const js_cat_slider = new Swiper(".js_cat_slider", {
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const js_cat_single_slider = new Swiper(".js_cat_single_slider", {
  slidesPerView: 1,
  spaceBetween: 0,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
    },
  },
});

const js_events_slider_nav = new Swiper(".js_events_slider_nav", {
  spaceBetween: 10,
  slidesPerView: 5,
  freeMode: true,
  watchSlidesProgress: true,
});

const js_events_slider = new Swiper(".js_events_slider", {
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  thumbs: {
    swiper: js_events_slider_nav,
  },
});

const btn_menu = document.querySelector('.js_toggle_btn_menu');
const menu = document.querySelector('.js_menu');
btn_menu.addEventListener('click', (e) => {
  if(menu.classList.contains('active')) {
    menu.classList.remove('active');
    btn_menu.classList.remove('active');
  } else {
    menu.classList.add('active');
    btn_menu.classList.add('active');
  }
});